import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSidenav} from '@angular/material/sidenav';
import { Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  @ViewChild('drawer', { static: false }) usuarioMenu: MatSidenav;
  title = 'artkuseum';
  collections = false;
  policies = false;
  showSubmenu = false;
  submenuCollection: [] = [];
  sideImgCollections: string;
  sidenav = false;
  transparentMenu = true;
  cookieIsAccepted: boolean = false;
  constructor(private translate: TranslateService,
              public router: Router) {
    translate.setDefaultLang('en');
    router.events.subscribe((val) => {
      const url = val['url'];
      if (url  && ! url.includes(['home'])){
        console.log(url );
        this.transparentMenu = false;
      } else if (url  &&  url.includes('home')){
        this.transparentMenu = true;
      }
    });
  }
  ngOnInit(): void {
    const cookie = localStorage.getItem('cookie');
    this.cookieIsAccepted = cookie === 'accepted';
  }

  onActivate(event): void  {
    window.scroll(0, 0 );
  }
  close(): void{
    this.usuarioMenu.close();
    this.sidenav = false;
  }
  openMenu(): void{
    this.sidenav = true;
    this.usuarioMenu.toggle();
  }
  menuRouterLink(route: string): void{
    this.router.navigate([route]);
    this.close();
  }
  routerLink(route: string): void{
    this.router.navigateByUrl(route);
  }

}
