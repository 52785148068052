
<div>
  <form class="example-form" >
    <mat-form-field color="accent" class="example-full-width" appearance="outline">
      <!--<mat-label>Hi, how are you doing?</mat-label>-->
      <input
        type="email" matInput [formControl]="emailFormControl"
             placeholder="Hi, I am Kuse bot"><!--[errorStateMatcher]="matcher"-->
      <!--<mat-hint>Errors appear instantly!</mat-hint>-
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>-->
     <!-- <button  matSuffix mat-icon-button aria-label="Clear" >
        <mat-icon>close</mat-icon>
      </button>-->
      <button  matSuffix mat-icon-button >
        <!--<mat-icon>search</mat-icon>-->
      </button>
    </mat-form-field>
  </form>
 </div>

