<div class="current-collection">
  <div class="header">
    <p class="header-p"> Exhibitions </p>
  </div>


  <div class="current-collection-content">

    <div class="card">
      <img [src]="'../../../../'+ '../assets/img/collections/belarus/war_nature_morte.jpg'" alt="">
      <div class="card-content">
        <div class="chip">Going on Now</div>
        <h3 class="title">Atelier -Barcelona, Spain</h3>
        <p> Contact for details</p>
        <div>
          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="">About Karma</a>
          </div>
          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="">Directions</a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <img [src]="'../../../../'+ '../assets/img/collections/belarus/war_nature_morte.jpg'"
           alt="">
      <div class="card-content">
        <div class="chip">Going on Now</div>
        <h3 class="title">Karma - Warsaw, Poland</h3>
        <p> October - November 2023</p>
        <div>
          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="">About Karma</a>
          </div>
          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="">Directions</a>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>


