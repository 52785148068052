import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-exhibitions',
  templateUrl: './current-exhibitions.component.html',
  styleUrls: ['./current-exhibitions.component.scss']
})
export class CurrentExhibitionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
