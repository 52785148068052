
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCwwP_CppZXsEC1NhXIMcaJcSx5GGCF2pQ',
    authDomain: 'artkuseum-9820b.firebaseapp.com',
    projectId: 'artkuseum-9820b',
    storageBucket: 'artkuseum-9820b.appspot.com',
    messagingSenderId: '651281759501',
    appId: '1:651281759501:web:37d2bb4454d0ac5aab8444',
    measurementId: 'G-KEDLE4SM3B'
  }
};
