<div class="footer">
  <div>
    <div class="left_side">
      <p class="header-p">{{ 'menu.whatMore' | translate }}</p>
      <div class="content-1">
        <div class="links">
          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="https://www.instagram.com/artkuseum/"
               target="_blank">{{ 'menu.instagram' | translate }}</a>
          </div>

          <div class="follow-links">
            <button mat-mini-fab aria-label="Example icon button with a plus one icon">
              <mat-icon>chevron_right</mat-icon>
            </button>
            <a href="https://www.linkedin.com/in/xenia-turava/"
               target="_blank">{{ 'menu.linkedIn' | translate }}</a>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="footer-content">
    <p [innerHTML]="'menu.copyrights' | translate"></p>
  </div>
</div>
