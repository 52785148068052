import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  isVisible = true;
  constructor() { }

  ngOnInit(): void {
  }
  public hide(): void{
    this.isVisible = false;
    localStorage.setItem('cookie', 'accepted');
  }
}
