import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CurrentExhibitionsComponent} from './components/current-exhibitions/current-exhibitions.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [
    CurrentExhibitionsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [CurrentExhibitionsComponent]
})
export class SharedModule { }
