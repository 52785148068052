import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-input-guide',
  templateUrl: './input-guide.component.html',
  styleUrls: ['./input-guide.component.scss']
})
export class InputGuideComponent implements OnInit {
  emailFormControl = new FormControl('');

 // matcher = new MyErrorStateMatcher();
  constructor() { }

  ngOnInit(): void {
  }
}
