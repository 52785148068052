import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'artwork/:collectionId/:id',
    loadChildren: () => import('./pages/artwork/artwork.module').then(m => m.ArtworkModule)
  },
  {
    path: 'collection',
    loadChildren: () => import('./pages/collections/collections.module').then(m => m.CollectionsModule)
  },
  {
    path: 'linktree',
    loadChildren: () => import('./pages/linktree/linktree.module').then(m => m.LinktreeModule)
  },
  /* {
     path: 'help',
     loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule)
   },*/
  // { path: '',   redirectTo: 'home', pathMatch: 'full' },
  //{  path: '**', redirectTo: 'home' },
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home'}, //linktree
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes,
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64]
      }
    ),
    CommonModule, TranslateModule
  ],
  exports: [RouterModule, TranslateModule
  ]
})
export class AppRoutingModule {
}
