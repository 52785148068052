<mat-drawer-container
  [ngClass]="{'mat-drawer-container-opened': sidenav}"
  class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav"
              mode="push"
              position="end">
    <div class="row close">
      <button mat-mini-fab (click)="close()">
        <mat-icon>west</mat-icon>

      </button>
      <h4>@Artkuseum </h4>
    </div>
    <div class="row drawer-content">
      <div class="avatar-drawer">
        <img
          routerLink="/home" routerLinkActive="active"
          class="avatar" src="../assets/img/collections/landing/gallero_artkuseum0.jpg" alt="">
      </div>
      <button class="col-md-10 menu-btns" mat-button
              (click)="menuRouterLink('/home')">
        {{"menu.home" | translate }}
      </button>

      <button class="col-md-10 menu-btns" mat-button
              (click)="menuRouterLink('/gallery')">
        {{"menu.gallery" | translate }}
      </button>

      <button class="col-md-10 menu-btns" mat-button
              (click)="menuRouterLink('/contact')">
        {{"menu.contact" | translate }}
      </button>

      <button class="col-md-10 menu-btns" mat-button
              [innerHTML]="'menu.policies' | translate ">
      </button>

      <button class="col-md-10 menu-btns" mat-button>
        {{"menu.cookies" | translate }}
      </button>
    </div>
    <div class="copyrights">
      <p [innerHTML]="'menu.copyrights' | translate"></p>
    </div>

  </mat-drawer>

  <mat-toolbar class="row toolbar"
               [ngClass]="{'menu-background': !transparentMenu,
             'transparent-background': transparentMenu}">

    <div class=" owner col-3">
      <button
        routerLink="/home" routerLinkActive="active"
        mat-button>
        <img class="avatar " src="../assets/img/collections/landing/logo_artkuseum.jpg" alt="">
        <span class="avatar-text ">@Artkuseum</span></button>
    </div>

    <div class="col-4"></div>
    <button class="col-md-1 menu-follow" mat-button
            (click)="routerLink('/home')"
            color="primary">  {{"menu.home" | translate }}
    </button>
    <button class="col-md-1 menu-follow" mat-button
            (click)="routerLink('/gallery')"
            color="primary">  {{"menu.gallery" | translate }}
    </button>

    <!-- <button class="col-md-1 menu-follow" mat-button
             [matMenuTriggerFor]="menu"
             color="primary">  {{"menu.about" | translate }}
     </button>
      Follow menu -->

    <mat-menu #menu="matMenu" yPosition="below" xPosition="after"
              class="mat-elevation-z0 col-12 collections1">
      <button mat-menu-item>Instagram
        <img class="icons" src="../assets/icons/instagram.png" alt="">
      </button>
      <button mat-menu-item>OpenSea
        <img class="icons" src="../assets/icons/opensea.svg" alt="">
      </button>
      <button mat-menu-item>Copy Link
        <mat-icon>link</mat-icon>
      </button>
      <button mat-menu-item>Spotify
        <img class="icons" src="../assets/icons/spotify.png" alt="">
      </button>
    </mat-menu>


    <button class="col-1 phone-menu"
            mat-icon-button color="primary"
            (click)="openMenu()"
            aria-label="Example icon button with a menu icon">
      <mat-icon>menu</mat-icon>
    </button>

  </mat-toolbar>


  <router-outlet></router-outlet>
  <app-cookies *ngIf="!cookieIsAccepted"></app-cookies>
  <app-footer></app-footer>
</mat-drawer-container>


