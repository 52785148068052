<div class="cookie-parent  " *ngIf="isVisible">
  <div class="container ">
    <!--<div id="header">
      <img class="avatar" src="../assets/img/KuseLogo.png" alt="">
    </div>-->
    <div class="content-cookie  row">
      <div class="col-9 cookie-description">
        <p>{{"cookies.cookieMessage" | translate }}
          <a href="">{{"cookies.cookies" | translate }}</a>
          {{"cookies.and" | translate }}
          <a href=""> {{"cookies.termsAndConditions" | translate }}</a>
        </p>
      </div>
      <div class="col-3 buttons">
        <button class="accept" mat-flat-button
                (click)="hide()"
                aria-label="Example icon button with a menu icon">
          {{"cookies.accept" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class=""></div>
</div>

